// import services from './services';

import { useLoading } from '@/components/loading/LoadingService';
import { api } from './services';

const loading = useLoading();

class core {
    constructor(opt = { storage: 'localStorage', tokenValid: 21600000 }) {
        this.token = undefined;
        this.user = undefined;
        this.accessLevel = undefined;
        this.tokenValid = opt.tokenValid || 21600000;

        this.logged = false;

        this.storage;
        if (opt.storage == 'localStorage') {
            this.storage = window.localStorage;
        } else {
            this.storage = window.sessionStorage;
        }
    }
    load() {
        if (this.storage.ds) {
            try {
                console.log('login de usuário encontrado');
                var ds = JSON.parse(atob(this.storage.getItem('ds')));
            } catch (error) {
                console.error({
                    title: 'Erro crítico.',
                    text: `Erro no sistema de parse, recarregue a página ou entre em contato.`,
                    color: 'danger',
                    error,
                });
            }

            this.token = ds.token;
            this.user = ds.data;
            this.accessLevel = ds.userType;
            this.timingToken = ds.date;

            this.logged = true;
        } else {
            console.log('usuário não está logado');
            this.logged = false;
        }
    }
    isAuthenticated() {
        if (this.logged) {
            var period = this.timingToken - Date.now(); //verifica quanto tempo o tokem esta guardado
            if (-period < this.tokenValid) {
                // se for menor que a validade do token é valido
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    login(data) {
        return new Promise((resolve, reject) => {
            if (data == undefined)
                reject({ title: 'Erro crítico.', text: 'Formato de dados inválido', color: 'danger' });

            api.post('/login', data)
                .then((res) => {
                    var dataToStringFy = {
                        token: res.data.token.token,
                        data: res.data.data.login,
                        userType: res.data.data.login.user_type,
                        date: Date.now(),
                    };
                    try {
                        this.storage.setItem('ds', btoa(JSON.stringify(dataToStringFy)));
                        this.load();
                        resolve(dataToStringFy);
                    } catch (error) {
                        reject({
                            title: 'Erro crítico.',
                            text: `Erro no sistema de autenticação, recarregue a página ou entre em contato.`,
                            color: 'danger',
                        });
                        console.error(error);
                    }
                })
                .catch((err) => reject(err));
        });
    }
    logout() {
        return new Promise((resolve, reject) => {
            if (this.logged) {
                // services.post('/logout').finally(() => {
                try {
                    this.storage.removeItem('ds');
                    this.logged = false;
                    this.token = undefined;
                    this.user = undefined;
                    this.accessLevel = undefined;

                    resolve()
                } catch (error) {
                    console.error({
                        title: 'Erro crítico.',
                        text: `Erro no sistema de logout, recarregue a página ou entre em contato.`,
                        color: 'danger',
                        error,
                    });
                    reject({
                        title: 'Erro crítico.',
                        text: `Erro no sistema de logout, recarregue a página ou entre em contato.`,
                        color: 'danger',
                        error,
                    });
                }
                // });
            } else {
                // console.warn('[auth] Usuário não esta logado');
                // reject('[auth] Usuário não esta logado');
            }
        });
    }
    resetPassword(token, password) {
        return new Promise((resolve, reject) => {
            loading.open();
            api.put(`/change-password`, {
                token,
                password,
            })
                .then((response) => {
                    loading.close();
                    resolve(response);
                })
                .catch((error) => {
                    loading.close();
                    reject({ isError: true, error });
                });
        });
    }
    sendResetPassword(email) {
        return new Promise((resolve, reject) => {
            loading.open();
            api.post(`/forgot-password`, { email })
                .then((response) => {
                    loading.close();
                    resolve(response);
                })
                .catch((error) => {
                    loading.close();
                    reject({ isError: true, error });
                });
        });
    }
    getData() {
        if (this.logged) {
            var ds = JSON.parse(atob(this.storage.getItem('ds')));
            return ds.data;
        } else {
            console.warn('[auth] Usuário não esta logado');
            return { text: 'Usuário não autenticado ' };
        }
    }
    getToken() {
        if (this.logged) {
            var ds = JSON.parse(atob(this.storage.getItem('ds')));
            return ds.token;
        } else {
            console.warn('[auth] Usuário não esta logado');
            return { text: 'Usuário não autenticado ' };
        }
    }
    getUserType() {
        if (this.logged) {
            var ds = JSON.parse(atob(this.storage.getItem('ds')));
            return ds.userType;
        } else {
            console.warn('[auth] Usuário não esta logado');
            return { text: 'Usuário não autenticado ' };
        }
    }
}

const authentication = new core({
    storage: 'localStorage',
    tokenValid: 21600000,
});

export default authentication;
