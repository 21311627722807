import axios from 'axios';
var MockAdapter = require('axios-mock-adapter');

var mock;

if (process.env.VUE_APP_USE_API_MOCKUP == 'true') {
    mock = new MockAdapter(axios, { delayResponse: 2000 });

    // arguments for reply are (status, data, headers)

    mock.onPost('/login').reply(function (config) {
        var data = JSON.parse(config.data);
        if (data.username == '33266648854') {
            return axios.get('/loginCliente');
        } else {
            return axios.get('/loginADM');
        }
    });

    mock.onGet('/loginADM').reply(200, {
        token: {
            type: 'bearer',
            token: 'NQ.HEGi4P4RtgqZANbtOJmZFf_qlhOp4L8WikrEPZ_fkXaHDDTQn9pKaHUjLhB_',
            expires_at: '2022-05-05T20:37:50.141+00:00',
        },
        data: {
            loginInfo: {
                id: 1,
                email: 'walter.matheus@simplecyclestudio.com.br',
                cpf: '123456789',
                last_login: '2022-05-05T14:36:04.000Z',
                type_user: 'client',
                adm_id: null,
                client_id: 1,
                worker_id: null,
                created_at: '2022-05-05T14:32:38.000+00:00',
                updated_at: '2022-05-05T14:32:38.000+00:00',
            },
            userInfo: {
                id: 1,
                name: 'Jefferson Amaral',
                tel: '11 1234-5678',
                sub_type: 'client',
                created_at: '2022-05-05T14:32:38.000+00:00',
                updated_at: '2022-05-05T14:32:38.000+00:00',
            },
        },
    });

    mock.onGet('/loginCliente').reply(200, {
        token: {
            type: 'bearer',
            token: 'NQ.HEGi4P4RtgqZANbtOJmZFf_qlhOp4L8WikrEPZ_fkXaHDDTQn9pKaHUjLhB_',
            expires_at: '2022-05-05T20:37:50.141+00:00',
        },
        data: {
            loginInfo: {
                id: 1,
                email: 'walter.matheus@simplecyclestudio.com.br',
                cpf: '123456789',
                last_login: '2022-05-05T14:36:04.000Z',
                type_user: 'client',
                adm_id: null,
                client_id: 1,
                worker_id: null,
                created_at: '2022-05-05T14:32:38.000+00:00',
                updated_at: '2022-05-05T14:32:38.000+00:00',
            },
            userInfo: {
                id: 1,
                name: 'Jefferson Amaral',
                tel: '11 1234-5678',
                sub_type: 'client',
                created_at: '2022-05-05T14:32:38.000+00:00',
                updated_at: '2022-05-05T14:32:38.000+00:00',
            },
        },
    });

    mock.onPost('/forgotPassword').reply(function () {
        return [
            200,
            {
                mensage: 'Enviado',
            },
        ];
    });

    mock.onPost('/changePassByPass').reply(function () {
        return [
            200,
            {
                mensage: 'Enviado',
            },
        ];
    });

    // mock.onGet('/memories').reply(200, [
    //     {
    //         id: 1,
    //         name: 'Memória teste',
    //         status: 1,
    //         enterprise_name: 'Google',
    //         total: 3,
    //     },
    //     {
    //         id: 2,
    //         name: 'Memória teste',
    //         status: 1,
    //         enterprise_name: 'Google2',
    //         total: 1,
    //     },
    //     {
    //         id: 3,
    //         name: 'Memória teste',
    //         status: 1,
    //         enterprise_name: 'Google',
    //         total: 0,
    //     },
    //     {
    //         id: 4,
    //         name: 'Memória teste5',
    //         status: 1,
    //         enterprise_name: 'Google',
    //         total: 0,
    //     },
    // ]);

    // mock.onGet(/\/indicators\?memory_id=\d+/).reply(200, {
    //     message: 'Listagem efetuada com sucesso',
    //     data: {
    //         total: 3,
    //         perPage: 10,
    //         page: 1,
    //         lastPage: 1,
    //         data: [
    //             {
    //                 id: 1,
    //                 title: 'Teste',
    //                 desc: 'Testando',
    //                 slide: '',
    //                 enterprise_id: 1,
    //                 enterpriseName: 'Google',
    //             },
    //             {
    //                 id: 2,
    //                 title: 'Teste 2',
    //                 desc: 'Testando 2',
    //                 slide: '',
    //                 enterprise_id: 1,
    //                 enterpriseName: 'Google',
    //             },
    //             {
    //                 id: 4,
    //                 title: 'Inicial Indicator',
    //                 desc: 'Indicador inicial com update',
    //                 slide: '1',
    //                 enterprise_id: 1,
    //                 enterpriseName: 'Google',
    //             },
    //         ],
    //     },
    // });

    // mock.onGet(/\/indicators\/\d+/).reply(200, {
    //     message: 'Listagem efetuada com sucesso',
    //     data: {
    //         id: 4,
    //         title: 'Inicial Indicator',
    //         desc: 'Indicador inicial',
    //         slide: '1',
    //         memorie_id: 1,
    //         created_at: null,
    //         updated_at: null,
    //         files: {
    //             id: 7,
    //             name: 'bfd87296cacd09ccd02d389e51ac48c0.jpeg',
    //             size: '102259',
    //             hash: 'bfd87296cacd09ccd02d389e51ac48c0',
    //             URL: 'indicator/bfd87296cacd09ccd02d389e51ac48c0.jpeg',
    //             type: '',
    //             memorie_id: null,
    //             enterprise_id: null,
    //             indicator_id: 4,
    //             created_at: null,
    //             updated_at: null,
    //         },
    //         metrics: [],
    //     },
    // });

    // mock.onGet('/metrics').reply(200, {
    //     message: 'Listagem efetuada com sucesso',
    //     data: [
    //         {
    //             type: 'statistics',
    //             data: {
    //                 title: ['Team B', 'Team A', 'Team A', 'Team A'],
    //                 value: [44, 58, 'Ola a Todos', 34],
    //                 icon: ['search', 'trash-alt', 'edit', 'edit'],
    //             },
    //         },
    //         {
    //             type: 'line',
    //             title: 'Grafico de Linha',
    //             description: 'Exemplo de gráfico',
    //             data: {
    //                 lines: [
    //                     {
    //                         name: 'Visits',
    //                         data: [100, 120, 90, 170, 130, 160, 140, 240, 220, 180, 270, 280, 375],
    //                     },
    //                     {
    //                         name: 'Clicks',
    //                         data: [60, 80, 70, 110, 80, 100, 90, 180, 160, 140, 200, 220, 275],
    //                     },
    //                     {
    //                         name: 'Sales',
    //                         data: [20, 40, 30, 70, 40, 60, 50, 140, 120, 100, 140, 180, 220],
    //                     },
    //                 ],
    //                 xaxis: {
    //                     categories: [
    //                         '7/12',
    //                         '8/12',
    //                         '9/12',
    //                         '10/12',
    //                         '11/12',
    //                         '12/12',
    //                         '13/12',
    //                         '14/12',
    //                         '15/12',
    //                         '16/12',
    //                         '17/12',
    //                         '18/12',
    //                         '19/12',
    //                         '20/12',
    //                     ],
    //                     labels: {
    //                         rotate: -45,
    //                     },
    //                     title: {
    //                         text: 'Dias',
    //                     },
    //                 },
    //                 yaxis: {
    //                     title: {
    //                         text: 'Vendas',
    //                     },
    //                 },
    //             },
    //         },
    //         {
    //             type: 'area',
    //             title: 'Grafico de area',
    //             description: 'Exemplo de gráfico',
    //             data: {
    //                 lines: [
    //                     {
    //                         name: 'Visits',
    //                         data: [100, 120, 90, 170, 130, 160, 140, 240, 220, 180, 270, 280, 375],
    //                     },
    //                     {
    //                         name: 'Clicks',
    //                         data: [60, 80, 70, 110, 80, 100, 90, 180, 160, 140, 200, 220, 275],
    //                     },
    //                     {
    //                         name: 'Sales',
    //                         data: [20, 40, 30, 70, 40, 60, 50, 140, 120, 100, 140, 180, 220],
    //                     },
    //                 ],
    //                 xaxis: {
    //                     categories: [
    //                         '7/12',
    //                         '8/12',
    //                         '9/12',
    //                         '10/12',
    //                         '11/12',
    //                         '12/12',
    //                         '13/12',
    //                         '14/12',
    //                         '15/12',
    //                         '16/12',
    //                         '17/12',
    //                         '18/12',
    //                         '19/12',
    //                         '20/12',
    //                     ],
    //                     labels: {
    //                         rotate: -45,
    //                     },
    //                     title: {
    //                         text: 'Dias',
    //                     },
    //                 },
    //                 yaxis: {
    //                     title: {
    //                         text: 'Vendas',
    //                     },
    //                 },
    //             },
    //         },

    //         {
    //             type: 'statistics',
    //             data: {
    //                 title: ['Team A'],
    //                 value: [44],
    //                 icon: ['analytics'],
    //             },
    //         },

    //         {
    //             type: 'statistics',
    //             data: {
    //                 title: ['Team A'],
    //                 value: [44],
    //                 icon: ['analytics'],
    //             },
    //         },
    //         {
    //             type: 'statistics',
    //             data: {
    //                 title: ['Team B'],
    //                 value: [44],
    //                 icon: ['clipboard'],
    //             },
    //         },

    //         {
    //             type: 'bar_vertical',
    //             title: 'Grafico de coluna',
    //             description: 'Exemplo de gráfico',
    //             data: {
    //                 lines: [
    //                     {
    //                         name: 'Apple',
    //                         data: [90, 120, 55, 100, 80, 125, 175, 70, 88, 180],
    //                     },
    //                     {
    //                         name: 'Samsung',
    //                         data: [85, 170, 30, 40, 95, 90, 30, 110, 62, 20],
    //                     },
    //                 ],
    //                 xaxis: {
    //                     categories: [
    //                         '7/12',
    //                         '8/12',
    //                         '9/12',
    //                         '10/12',
    //                         '11/12',
    //                         '12/12',
    //                         '13/12',
    //                         '14/12',
    //                         '15/12',
    //                         '16/12',
    //                     ],
    //                     labels: {
    //                         rotate: -45,
    //                     },
    //                     title: {
    //                         text: 'Dias',
    //                     },
    //                 },
    //                 yaxis: {
    //                     title: {
    //                         text: 'Vendas',
    //                     },
    //                 },
    //             },
    //         },

    //         {
    //             type: 'mix',
    //             title: 'Grafico de linha e coluna',
    //             description: 'Exemplo de gráfico',
    //             data: {
    //                 lines: [
    //                     {
    //                         name: 'Apple',
    //                         type: 'line',
    //                         data: [90, 120, 55, 100, 80, 125, 175, 70, 88, 180],
    //                     },
    //                     {
    //                         name: 'Samsung',
    //                         type: 'column',
    //                         data: [85, 170, 30, 40, 95, 90, 30, 110, 62, 20],
    //                     },
    //                 ],
    //                 xaxis: {
    //                     categories: [
    //                         '7/12',
    //                         '8/12',
    //                         '9/12',
    //                         '10/12',
    //                         '11/12',
    //                         '12/12',
    //                         '13/12',
    //                         '14/12',
    //                         '15/12',
    //                         '16/12',
    //                     ],
    //                     labels: {
    //                         rotate: -45,
    //                     },
    //                     title: {
    //                         text: 'Dias',
    //                     },
    //                 },
    //                 yaxis: {
    //                     title: {
    //                         text: 'Vendas',
    //                     },
    //                 },
    //             },
    //         },

    //         {
    //             type: 'bar_horizontal',
    //             title: 'Grafico de coluna',
    //             description: 'Exemplo de gráfico',
    //             data: {
    //                 lines: [
    //                     {
    //                         name: 'Apple',
    //                         data: [90, 120, 55, 100, 80, 125, 175, 70, 88, 180],
    //                     },
    //                     {
    //                         name: 'Samsung',
    //                         data: [85, 100, 30, 40, 95, 90, 30, 110, 62, 20],
    //                     },
    //                     {
    //                         name: 'Samsung2',
    //                         data: [95, 300, 20, 50, 75, 110, 30, 210, 72, 60],
    //                     },
    //                 ],
    //                 xaxis: {
    //                     categories: [
    //                         '7/12',
    //                         '8/12',
    //                         '9/12',
    //                         '10/12',
    //                         '11/12',
    //                         '12/12',
    //                         '13/12',
    //                         '14/12',
    //                         '15/12',
    //                         '16/12',
    //                     ],
    //                     labels: {
    //                         rotate: -45,
    //                     },
    //                     title: {
    //                         text: 'Dias',
    //                     },
    //                 },
    //                 yaxis: {
    //                     title: {
    //                         text: 'Vendas',
    //                     },
    //                 },
    //             },
    //         },

    //         {
    //             type: 'pie',
    //             title: 'Grafico de pizza',
    //             description: 'Exemplo de gráfico',
    //             data: {
    //                 title: ['Operational', 'Networking', 'Hiring', 'R&D'],
    //                 valor: [44, 55, 13, 43],
    //             },
    //         },
    //         {
    //             type: 'donut',
    //             title: 'Grafico de rosca',
    //             description: 'Exemplo de gráfico',
    //             data: {
    //                 title: ['Operational', 'Networking', 'Hiring', 'R&D'],
    //                 valor: [44, 55, 13, 43],
    //             },
    //         },

    //         {
    //             type: 'radialBar',
    //             title: 'Grafico de rosca',
    //             description: 'Exemplo de gráfico',
    //             data: {
    //                 title: ['Operational'],
    //                 valor: [44],
    //             },
    //         },

    //         {
    //             type: 'list',
    //             title: 'Grafico de Lista',
    //             description: 'Exemplo de gráfico',
    //             data: {
    //                 title: ['Operational', 'Networking', 'Hiring', 'R&D'],
    //                 valor: [44, 55, 13, 43],
    //             },
    //         },

    //         {
    //             type: 'image',
    //             title: 'Grafico de Imagem',
    //             description: 'Exemplo de gráfico',
    //             image: img.image,
    //         },
    //     ],
    // });

    // mock.onGet('/clients').reply(200, {
    //     data: [
    //         {
    //             id: 1,
    //             name: 'Daniel',
    //             cargo: 'Diretor',
    //             status: 1,
    //             email: 'tez@gmail.com',
    //             corp: {
    //                 id: 1,
    //                 name: 'Google',
    //             },
    //         },
    //         {
    //             id: 2,
    //             name: 'Daniel',
    //             cargo: 'Diretor',
    //             status: 1,
    //             email: 'tez@gmail.com',
    //             corp: {
    //                 id: 1,
    //                 name: 'Google',
    //             },
    //         },
    //         {
    //             id: 3,
    //             name: 'Daniel',
    //             cargo: 'Diretor',
    //             status: 1,
    //             email: 'tez@gmail.com',
    //             corp: {
    //                 id: 1,
    //                 name: 'Google',
    //             },
    //         },
    //     ],
    //     total: 1,
    //     perPage: 10,
    //     page: 1,
    //     lastPage: 1,
    //     message: {
    //         default: true,
    //         text: 'Listagem efetuada com sucesso',
    //     },
    // });

    // mock.onGet(/\/clients\/\d+/).reply(200, {
    //     message: 'Listagem efetuada com sucesso',
    //     data: [
    //         {
    //             id: 1,
    //             name: 'Daniel',
    //             cargo: 'Diretor',
    //             status: 0,
    //             email: 'tez@gmail.com',
    //             corp: {
    //                 id: 1,
    //                 name: 'Google',
    //             },
    //         },
    //     ],
    // });

    // mock.onGet(/\/clients\?corp=\d+/).reply(200, {
    //     data: [
    //         {
    //             id: 1,
    //             name: 'Daniel',
    //             cargo: 'Diretor',
    //             status: 1,
    //             email: 'tez@gmail.com',
    //             corp: {
    //                 id: 1,
    //                 name: 'Google',
    //             },
    //         },
    //         {
    //             id: 2,
    //             name: 'Daniel',
    //             cargo: 'Diretor',
    //             status: 1,
    //             email: 'tez@gmail.com',
    //             corp: {
    //                 id: 1,
    //                 name: 'Google',
    //             },
    //         },
    //         {
    //             id: 3,
    //             name: 'Daniel',
    //             cargo: 'Diretor',
    //             status: 1,
    //             email: 'tez@gmail.com',
    //             corp: {
    //                 id: 1,
    //                 name: 'Google',
    //             },
    //         },
    //     ],
    //     total: 1,
    //     perPage: 10,
    //     page: 1,
    //     lastPage: 1,
    //     message: {
    //         default: true,
    //         text: 'Listagem efetuada com sucesso',
    //     },
    // });

    // mock.onPost('/clients').reply(function () {
    //     return [
    //         200,
    //         {
    //             message: 'Usuário cadastrado com sucesso',
    //         },
    //     ];
    // });

    // mock.onPut(/\/clients\/\d+/).reply(function () {
    //     return [
    //         200,
    //         {
    //             message: 'Usuário editado com sucesso',
    //         },
    //     ];
    // });

    // mock.onGet('/corp').reply(200, {
    //     data: [
    //         {
    //             id: 1,
    //             name: 'Google',
    //             email: 'google@gmail.com',
    //             status: 1,
    //             adm_id: 1,
    //             created_at: null,
    //             updated_at: null,
    //             primary_color: '#ffffff',
    //             secundary_color: '#000',
    //             files: img.image,
    //         },
    //         {
    //             id: 2,
    //             name: 'Google',
    //             email: 'google@gmail.com',
    //             status: 1,
    //             adm_id: 1,
    //             created_at: null,
    //             updated_at: null,
    //             primary_color: '#ffffff',
    //             secundary_color: '#000',
    //             files: img.image,
    //         },
    //         {
    //             id: 3,
    //             name: 'Google',
    //             email: 'google@gmail.com',
    //             status: 0,
    //             adm_id: 1,
    //             created_at: null,
    //             updated_at: null,
    //             primary_color: '#ffffff',
    //             secundary_color: '#000',
    //             files: img.image,
    //         },
    //         {
    //             id: 4,
    //             name: 'Google',
    //             email: 'google@gmail.com',
    //             status: 1,
    //             adm_id: 1,
    //             created_at: null,
    //             updated_at: null,
    //             primary_color: '#ffffff',
    //             secundary_color: '#000',
    //             files: img.image,
    //         },
    //     ],
    //     total: 1,
    //     perPage: 10,
    //     page: 1,
    //     lastPage: 1,
    //     message: {
    //         default: true,
    //         text: 'Listagem efetuada com sucesso',
    //     },
    // });

    // mock.onGet(/\/corp\/\d+/).reply(function () {
    //     return [
    //         200,
    //         {
    //             message: 'Listagem efetuada com sucesso',
    //             data: {
    //                 id: 1,
    //                 name: 'Google',
    //                 email: 'google@gmail.com',
    //                 status: 1,
    //                 adm_id: 1,
    //                 created_at: null,
    //                 updated_at: null,
    //                 primary_color: '#ffffff',
    //                 secundary_color: '#000',
    //                 files: img.image,
    //             },
    //         },
    //     ];
    // });

    // mock.onPost('/corp').reply(function () {
    //     return [
    //         200,
    //         {
    //             message: 'Listagem efetuada com sucesso',
    //         },
    //     ];
    // });

    // mock.onPut('/corp').reply(function () {
    //     return [
    //         200,
    //         {
    //             message: 'Listagem efetuada com sucesso',
    //         },
    //     ];
    // });

    // mock.onGet('/adm').reply(200, {
    //     data: [
    //         {
    //             id: 1,
    //             name: 'Kaique',
    //             cargo: 'Gerente de projeto',
    //             status: 1,
    //             email: 'kaique2@gmail.com',
    //         },
    //         {
    //             id: 2,
    //             name: 'Kaique',
    //             cargo: 'Gerente de projeto',
    //             status: 1,
    //             email: 'kaique2@gmail.com',
    //         },
    //         {
    //             id: 3,
    //             name: 'Kaique',
    //             cargo: 'Gerente de projeto',
    //             status: 1,
    //             email: 'kaique2@gmail.com',
    //         },
    //         {
    //             id: 4,
    //             name: 'Kaique',
    //             cargo: 'Gerente de projeto',
    //             status: 0,
    //             email: 'kaique2@gmail.com',
    //         },
    //     ],
    //     total: 1,
    //     perPage: 10,
    //     page: 1,
    //     lastPage: 1,
    //     message: {
    //         default: true,
    //         text: 'Listagem efetuada com sucesso',
    //     },
    // });

    // mock.onGet(/\/adm\/\d+/).reply(function () {
    //     return [
    //         200,
    //         {
    //             message: 'Listagem efetuada com sucesso',
    //             data: {
    //                 id: 1,
    //                 name: 'Kaique',
    //                 cargo: 'Gerente de projeto',
    //                 status: 1,
    //                 email: 'kaique2@gmail.com',
    //             },
    //         },
    //     ];
    // });

    // mock.onPost('/adm').reply(function () {
    //     return [
    //         200,
    //         {
    //             message: 'Listagem efetuada com sucesso',
    //         },
    //     ];
    // });

    // mock.onPut('/adm').reply(function () {
    //     return [
    //         200,
    //         {
    //             message: 'Listagem efetuada com sucesso',
    //         },
    //     ];
    // });
}

export default axios;
