import { createStore } from 'vuex';

export default createStore({
    state: {
        states: {
            sp: {
                id: 1,
                status: 'Open',
            },
            ceara: {
                id: 2,
                status: 'Open',
            },
            parana: {
                id: 3,
                status: 'Open',
            },
        },
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {},
});
