<template>
    <div class="h-full">
        <router-view v-if="canRender" />
    </div>

    <ConfirmDialog :breakpoints="{ '960px': '75vw', '640px': '90vw' }"></ConfirmDialog>
    <Toast />
    <ModalLoading />

    <span class="fixed text-white text-20 right-3 bottom-1">{{ version }}</span>
</template>

<script>
    import splashScreen from '@/plugins/splashScreen';
    import authentication from '@/plugins/authentication';

    import { ref } from 'vue';
    export default {
        setup() {
            const canRender = ref(false);
            const openProtect = ref(true);
            const version = ref(window.version);

            authentication.load();

            splashScreen.off();
            canRender.value = true;


            return { canRender, openProtect, version };
        },
    };
</script>

<style lang="scss"></style>
