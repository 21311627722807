
class controleInicialApp {
    constructor() {
        this.elemente = document.getElementById('loading-bg');
        this.textLabel = document.getElementById('text-loading');

        window.addEventListener('offline', () => {
            this.on().mensagem('Oops parece que você nao esta conectado a internet. :( ');
        });
        window.addEventListener('online', () => {
            this.off();
        });
    }
    on = function () {
        this.elemente.style.display = 'block';
        return this;
    };
    off = function () {
        this.elemente.style.display = 'none';
        return this;
    };
    mensagem = function (msg) {
        this.textLabel.innerText = msg;
    };
}

const loading = new controleInicialApp();

// store.state.loadingAppConnection = false
// const install = (app) => {
//     app.config.globalProperties.$loading = loading
//     app.provide('$loading', loading)
// };

export default loading
