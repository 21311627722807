<template>
    <div class="h-full w-full">
        <SideNav :class="{ 'mobile-active': isMobile }" />

        <div class="nav-responsive flex-grow" :class="{ 'mobile-active': isMobile }">
            <router-view id="viewBase" v-slot="{ Component }">
                <transition name="fade" mode="out-in">
                    <component :is="Component" class="container w-full" />
                </transition>
            </router-view>
        </div>
    </div>
</template>

<script>
    import SideNav from '@/components/SideNav.vue';
    import { ref } from 'vue';
    export default {
        name: 'ViewBase',
        components: { SideNav },
        setup() {
            const isMobile = ref(false);

            return { isMobile };
        },
    };
</script>

<style lang="scss">
    .nav-responsive {
        padding: 0 0 0 200px;
        height: 100%;
        &.mobile-active {
            padding: 0 0 0 0;
        }
    }
</style>
