import './infos.js';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/css/index.css';
import './assets/scss/main.scss';

import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import { locale } from '@/plugins/primevue-locale-dictionary';

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import Password from 'primevue/password';
import ProgressSpinner from 'primevue/progressspinner';
import ScrollPanel from 'primevue/scrollpanel';
import Checkbox from 'primevue/checkbox';
import ConfirmDialog from 'primevue/confirmdialog';
import Divider from 'primevue/divider';
import ModalLoading from '@/components/loading/loading.vue';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import Textarea from 'primevue/textarea';
import BadgeDirective from 'primevue/badgedirective';
import InputNumber from 'primevue/inputnumber';
import Calendar from 'primevue/calendar';
import Avatar from 'primevue/avatar';
import Rating from 'primevue/rating';
import Galleria from 'primevue/galleria';
import Dialog from 'primevue/dialog';
import NavBar from '@/components/Navbar'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';

const app = createApp(App);

app.use(store).use(router).use(PrimeVue, { locale }).use(ConfirmationService).use(ToastService)

app.component('Button', Button);
app.component('InputText', InputText);
app.component('InputMask', InputMask);
app.component('Password', Password);
app.component('ProgressSpinner', ProgressSpinner);
app.component('ScrollPanel', ScrollPanel);
app.component('Checkbox', Checkbox);
app.component('ConfirmDialog', ConfirmDialog);
app.component('Toast', Toast);
app.component('Divider', Divider);
app.component('ModalLoading', ModalLoading);
app.component('Dropdown', Dropdown);
app.component('MultiSelect', MultiSelect);
app.component('Textarea', Textarea);
app.component('InputNumber', InputNumber);
app.component('Calendar', Calendar);
app.component('Avatar', Avatar);
app.component('Rating', Rating);
app.component('Galleria', Galleria);
app.component('Dialog', Dialog);
app.component('NavBar', NavBar);
app.component('DataTable', DataTable);
app.component('Column', Column);

app.directive('badge', BadgeDirective);

app.mount('#app');
