import LoadingEventtBus from '@/components/loading/LoadingEventtBus';

export function useLoading() {
    const ToastService = {
        open: () => {
            LoadingEventtBus.emit('open');
        },
        close: () => {
            LoadingEventtBus.emit('close');
        },
    };
    return ToastService;
}
