import authentication from './authentication';

function saveFileCache(url, file) {
    if (document.CACHEFILE == undefined) {
        document.CACHEFILE = {};
    }
    document.CACHEFILE[url] = file;
}

function getFileCache(url) {
    if (document.CACHEFILE == undefined) {
        document.CACHEFILE = {};
    }

    if (document.CACHEFILE[url] == undefined) {
        return false;
    } else {
        return document.CACHEFILE[url];
    }
}

export async function urltoFile(base, url = '', type = null, id = null, name = '') {
    console.log(`${url} ${base}`);

    const types = { pdf: 'application/', jpg: 'image/', png: 'image/', jpeg: 'image/' };
    var token = '';
    var isBase64 = /base64.*/.test(base);
    var fullURL = '';
    var typeFile = '';

    try {
        token = authentication.token;
    } catch {
        token = '';
        console.log(`Token is not defined`);
    }

    if (isBase64) {
        fullURL = base;
        typeFile = [base.split(';')[0].split(':')[1].split('/')[1], base.split(';')[0].split(':')[1]];
    } else {
        fullURL = base + url;
        console.log(type);
        if (type == null) {
            // var infoFile = url.split('.')[1];
            var infoFile = url;
            console.log(url);
            if (infoFile == undefined) throw new Error('Tipo de url inválido');

            typeFile = [infoFile, types[infoFile] + infoFile];
        } else {
            typeFile = type;
        }
    }

    var fileName = name === '' ? `example.${typeFile}` : name;

    var file = getFileCache(fullURL);

    if (file) {
        return Promise.resolve(file);
    } else {
        try {
            var headers = new Headers({
                authorization: `Bearer ${token}`,
                Accept: '*/*, application/json, text/plain',
            });
            var config = { method: 'GET', headers };
            console.log(`fullURL: ${fullURL}`);

            var response = await fetch(new Request(fullURL, config));

            if (response.status === 404) return Promise.resolve(null);

            var contentType = response.headers.get('content-type');

            if (contentType && contentType.indexOf('application/json') !== -1) {
                const json = await response.json();

                return Promise.resolve(json);
            } else {
                const buf = await response.arrayBuffer();
                const fileFinal = new File([buf], fileName, { type: typeFile });

                if (id != null) fileFinal.id = id;

                if (typeFile != 'pdf') {
                    saveFileCache(fullURL, fileFinal);
                }

                return Promise.resolve(fileFinal);
            }
        } catch (error) {
            console.log('1');

            return Promise.resolve(null);
        }
    }
}

export async function downloadFile(url, nameFile, token) {
    try {
        const headers = new Headers({
            authorization: `Bearer ${token}`,
            Accept: '*/*, application/json, text/plain',
        });
        const config = { method: 'GET', headers };

        const request = await fetch(new Request(url, config));

        const requestContentType = request.headers.get('content-type');
        if (requestContentType && requestContentType.indexOf('application/json') !== -1) {
            const json = await request.json();
            return Promise.reject(json);
        }

        if (!request.ok) return Promise.reject(null);

        const blob = await request.blob();

        const href = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = href;

        a.download = nameFile;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(href);

        document.removeChild(a);
    } catch (errors) {
        return Promise.resolve(null);
    }
}

export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

export function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

export function cpfMask(v) {
    v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); //Coloca um hífen entre o terceiro e o quarto dígitos
    return v;
}

export function mphone(v) {
    var r = v.replace(/\D/g, '');
    r = r.replace(/^0/, '');
    if (r.length > 10) {
        r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (r.length > 5) {
        r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3');
    } else if (r.length > 2) {
        r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else {
        r = r.replace(/^(\d*)/, '($1');
    }
    return r;
}
