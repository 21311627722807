<template>
    <div class="flex w-12/12">
        <div
            v-if="back_button"
            class="flex items-center justify-center w-16 card-padrao my-5 mr-2 px-2 py-6 cursor-pointer"
            @click="go_back"
        >
            <i class="pi pi-angle-left" style="font-size: 2rem"></i>
        </div>
        <div class="flex flex-grow bg-white my-5 p-3 card-padrao justify-between items-center">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import { useRouter } from 'vue-router';
    export default {
        props: {
            back_button: {
                type: Boolean,
                default: () => false,
            },
            back_to: {
                type: String,
                default: () => '',
            },
        },
        setup(props) {
            const router = useRouter();

            function go_back() {
                if (props.back_to === '') router.go(-1);
                else router.push(props.back_to);
            }
            return { go_back };
        },
    };
</script>

<style></style>
