<template>
    <Teleport to="body">
        <div v-if="open" class="modal flex top-0 w-full h-full justify-center items-center">
            <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="3"
                fill="var(--surface-ground)"
                animationDuration=".5s"
            />
        </div>
    </Teleport>
</template>

<script>
    import LoadingEventtBus from './LoadingEventtBus';
    export default {
        name: 'ModalLoading',
        data() {
            return {
                open: false,
            };
        },
        methods: {
            openLoading() {
                this.open = true;
            },
            closeLoading() {
                this.open = false;
            },
        },
        mounted() {
            LoadingEventtBus.on('open', this.openLoading);
            LoadingEventtBus.on('close', this.closeLoading);
        },
        beforeUnmount() {
            LoadingEventtBus.off('open', this.openLoading);
            LoadingEventtBus.off('close', this.closeLoading);
        },
    };
</script>

<style lang="scss">
    .modal {
        background-color: rgba(#000000, 0.3);
        position: fixed;
        z-index: 9999;
    }
</style>
