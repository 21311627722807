import axios from 'axios';
import ToastEventBus from 'primevue/toasteventbus';
import auth from './authentication';
import { downloadFile } from '@/plugins/functions';

function useToast() {
    const ToastService = {
        add: (message) => {
            ToastEventBus.emit('add', message);
        },
        removeGroup: (group) => {
            ToastEventBus.emit('remove-group', group);
        },
        removeAllGroups: () => {
            ToastEventBus.emit('remove-all-groups');
        },
    };
    return ToastService;
}

const toast = useToast();

const loading = useLoading();

import axioMockup from './apiMockup'; //https://github.com/ctimmerm/axios-mock-adapter
import authentication from './authentication';
import { useLoading } from '@/components/loading/LoadingService';

var useMockup = process.env.VUE_APP_USE_API_MOCKUP;

var http;
if (useMockup == 'true') {
    http = axioMockup.create({
        baseURL: process.env.VUE_APP_API,
    });
} else {
    http = axios.create({
        baseURL: process.env.VUE_APP_API,
    });
}

http.interceptors.request.use((config) => {
    if (!config.headers.token) {
        if (config.url != '/login' && config.url != '/forgotPassword') {
            const token = auth.getToken();
            config.headers.authorization = `Bearer ${token}`;
        }
    }
    return config;
});

http.interceptors.response.use(
    (response) => {
        // console.log(response);
        console.log({
            method: response.config.method,
            from: response.config.url,
            reqHeaders: response.config.headers,
            reqData: response.config.data,
            r: response.data,
        });

        if (response.data.message) {
            toast.add({
                severity: 'success',
                // summary: 'Erro Grave',
                detail: response.data.message,
                life: 3000,
            });
        }

        if (response.data.error) {
            // core.$vs.notification({
            //     title: response.data.error.title,
            //     text: response.data.error.message,
            //     color: 'danger',
            //     position: 'top-right',
            // });
        }

        return response;
    },
    (error) => {
        console.log({ error });
        console.error({
            method: error.config.method,
            from: error.config.url,
            reqHeaders: error.config.headers,
            reqData: error.config.data,
            r: error.response.data,
            status: error.response.status,
        });

        if (error.response.status === 401) {
            toast.add({
                severity: 'error',
                summary: 'Autenticação',
                detail: 'O seu acesso espirou. Por favor entre novamente.',
                life: 3000,
            });

            authentication.logout();

            return Promise.reject(error);
        }

        if (JSON.stringify(error.response.data) === '{}') {
            toast.add({
                severity: 'error',
                summary: 'Erro',
                detail: 'Houve um erro para processar sua solicitação.',
                life: 3000,
            });

            return Promise.reject(error);
        }

        if (typeof error.response.data.message === 'string') {
            toast.add({
                severity: 'error',
                summary: error.response.data.code,
                detail: error.response.data.message,
                life: 6000,
            });
            return Promise.reject(error);
        }

        if (error.response.data.messages.errors) {
            let errors = error.response.data.messages.errors;
            for (let index = 0; index < errors.length; index++) {
                const element = errors[index];

                toast.add({
                    severity: 'error',
                    summary: element.field,
                    detail: element.message,
                    life: 6000,
                });
            }

            return Promise.reject(error);
        }

        toast.add({
            severity: 'error',
            summary: 'Erro',
            detail: 'Houve um erro não identificado',
            life: 3000,
        });

        // if (typeof error.response.data.error === 'string') {
        //     toast.add({
        //         severity: 'error',
        //         summary: 'Erro Grave',
        //         detail: error.response.data.error,
        //         life: 6000,
        //     });
        // } else {
        //     const errors = error.response.data.errors;
        //     if (typeof errors === 'string') {
        //         toast.add({
        //             severity: 'error',
        //             summary: error.response.data.error.title,
        //             detail: error.response.data.error.message,
        //             life: 6000,
        //         });
        //     } else {
        //         for (let index = 0; index < errors.length; index++) {
        //             const element = errors[index];

        //             toast.add({
        //                 severity: 'error',
        //                 summary: element.field,
        //                 detail: element.message,
        //                 life: 6000,
        //             });
        //         }
        //     }
        // }

        return Promise.reject(error);
    },
);

const api = {
    axios,
    instance: http,
    get(endpoint, body) {
        return http.get(`${endpoint}`, { params: body });
    },
    post(endpoint, body, config) {
        return http.post(endpoint, body, config);
    },
    put(endpoint, body, config) {
        console.log(body);
        return http.put(endpoint, body, config);
    },
    delete(endpoint, body) {
        return http.delete(endpoint, body);
    },
    busca(endpoint) {
        return http.get(endpoint);
    },
};

const adm = {
    getAll({ page = 1, perPage = 10, search = '', status = '' }) {
        return new Promise((resolve, reject) => {
            loading.open();
            http.get(`/adms?page=${page}&perPage=${perPage}&search=${search}&status=${status}`)
                .then((response) => {
                    loading.close();
                    resolve(response);
                })
                .catch((error) => {
                    loading.close();
                    reject({ isError: true, error });
                });
        });
    },
    add(data) {
        return new Promise((resolve, reject) => {
            loading.open();
            http.post(`/adms`, data)
                .then((response) => {
                    loading.close();
                    resolve(response);
                })
                .catch((error) => {
                    loading.close();
                    reject({ isError: true, error });
                });
        });
    },
    get(admID) {
        return new Promise((resolve, reject) => {
            loading.open();
            http.get(`/adms/${admID}`)
                .then((response) => {
                    loading.close();
                    resolve(response);
                })
                .catch((error) => {
                    loading.close();
                    reject({ isError: true, error });
                });
        });
    },
    update(admID, admInstance) {
        return new Promise((resolve, reject) => {
            loading.open();
            http.put(`/adms/${admID}`, {
                name: admInstance.name,
                post: admInstance.post,
                field: admInstance.field,
                status: admInstance.status,
            })
                .then((response) => {
                    loading.close();
                    resolve(response);
                })
                .catch((error) => {
                    loading.close();
                    reject({ isError: true, error });
                });
        });
    },
    disable(admInstance) {
        return new Promise((resolve, reject) => {
            loading.open();
            http.put(`/adms/${admInstance.id}`, {
                name: admInstance.name,
                post: admInstance.post,
                field: admInstance.field,
                status: 'inativo',
            })
                .then((response) => {
                    loading.close();
                    resolve(response);
                })
                .catch((error) => {
                    loading.close();
                    reject({ isError: true, error });
                });
        });
    },
};

const users = {
    getAll({ page = 1, perPage = 10, search = '', status = '', inscription_type = '' }) {
        return new Promise((resolve, reject) => {
            loading.open();
            http.get(
                `/users?page=${page}&perPage=${perPage}&search=${search}&status=${status}&inscription_type=${inscription_type}`,
            )
                .then((response) => {
                    loading.close();
                    resolve(response);
                })
                .catch((error) => {
                    loading.close();
                    reject({ isError: true, error });
                });
        });
    },
    add(data) {
        return new Promise((resolve, reject) => {
            loading.open();
            http.post(`/users`, data)
                .then((response) => {
                    loading.close();
                    resolve(response);
                })
                .catch((error) => {
                    loading.close();
                    reject({ isError: true, error });
                });
        });
    },
    update(idUser, data) {
        return new Promise((resolve, reject) => {
            loading.open();
            http.put(`/users/${idUser}`, data)
                .then((response) => {
                    loading.close();
                    resolve(response);
                })
                .catch((error) => {
                    loading.close();
                    reject({ isError: true, error });
                });
        });
    },
    get(userID) {
        return new Promise((resolve, reject) => {
            loading.open();
            http.get(`/users/${userID}`)
                .then((response) => {
                    loading.close();
                    resolve(response);
                })
                .catch((error) => {
                    loading.close();
                    reject({ isError: true, error });
                });
        });
    },
    getDecisionReport(userID) {
        return new Promise((resolve, reject) => {
            loading.open();

            downloadFile(
                process.env.VUE_APP_API + `user-decision-report/${userID}`,
                'RELATÓRIO DE TOMADA DE DECISÃO.xlsx',
                auth.getToken(),
            )
                .then(() => {
                    loading.close();

                    resolve();
                })
                .catch((error) => {
                    loading.close();
                    reject({ isError: true });
                    if (error === null) return;
                    toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: error.message,
                        life: 3000,
                    });
                });
        });
    },
    getFinalReport(userID) {
        return new Promise((resolve, reject) => {
            loading.open();
            http.get(`/user-report/${userID}`)
                .then((response) => {
                    loading.close();
                    resolve(response);
                })
                .catch((error) => {
                    loading.close();
                    reject({ isError: true, error });
                });
        });
    },
    research(userID) {
        return new Promise((resolve, reject) => {
            loading.open();
            http.post(`/hunter-send-research/${userID}`)
                .then((response) => {
                    loading.close();
                    resolve(response);
                })
                .catch((error) => {
                    loading.close();
                    reject({ isError: true, error });
                });
        });
    },
};

const metrics = {
    get() {
        return new Promise((resolve, reject) => {
            loading.open();
            http.get(`/metrics`)
                .then((response) => {
                    loading.close();
                    resolve(response);
                })
                .catch((error) => {
                    loading.close();
                    reject({ isError: true, error });
                });
        });
    },
    satistactionSurvey(initial_date, final_date) {
        return new Promise((resolve, reject) => {
            loading.open();

            downloadFile(
                process.env.VUE_APP_API + `metrics/satisfaction-survey?initial_date=${initial_date}&final_date=${final_date}`,
                'Relatório de perguntas de satisfação.xlsx',
                auth.getToken(),
            )
                .then(() => {
                    loading.close();

                    resolve();
                })
                .catch((error) => {
                    loading.close();
                    reject({ isError: true });
                    if (error === null) return;
                    console.log(error);
                    toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: error.message,
                        life: 3000,
                    });
                });
        });
    },
    userConsolidate(initial_date, final_date) {
        return new Promise((resolve, reject) => {
            loading.open();

            downloadFile(
                process.env.VUE_APP_API +
                    `user-consolidate-report?initial_date=${initial_date}&final_date=${final_date}`,
                'Conecta_Relatorio_Consolidado_Usuarios_Por_Valor.xlsx',
                auth.getToken(),
            )
                .then(() => {
                    loading.close();
                    resolve();
                })
                .catch((error) => {
                    loading.close();
                    reject({ isError: true });
                    if (error === null) return;
                    console.log(error);

                    if (error.messages.errors) {
                        error.messages.errors.forEach((element) => {
                            toast.add({
                                severity: 'error',
                                summary: 'Erro',
                                detail: element.message,
                                life: 8000,
                            });
                        });
                        return;
                    }
                    toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: error.message,
                        life: 3000,
                    });
                });
        });
    },
    userFinalConsolidate(initial_date, final_date) {
        return new Promise((resolve, reject) => {
            loading.open();

            downloadFile(
                process.env.VUE_APP_API +
                    `user-final-consolidate-report?initial_date=${initial_date}&final_date=${final_date}`,
                'Conecta_Relatorio_Consolidado_Parecer_Final.xlsx',
                auth.getToken(),
            )
                .then(() => {
                    loading.close();
                    resolve();
                })
                .catch((error) => {
                    loading.close();
                    reject({ isError: true });
                    if (error === null) return;
                    console.log(error);

                    if (error.messages.errors) {
                        error.messages.errors.forEach((element) => {
                            toast.add({
                                severity: 'error',
                                summary: 'Erro',
                                detail: element.message,
                                life: 8000,
                            });
                        });
                        return;
                    }
                    toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: error.message,
                        life: 3000,
                    });
                });
        });
    },
};

export { api, adm, users, metrics };
