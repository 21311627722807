import { createRouter, createWebHistory } from 'vue-router';
import aplication from '@/views/Aplication.vue';
import auth from '@/plugins/authentication';

const login = () => import(/* webpackChunkName: "login" */ '@/views/login/login');
const ForgotPassword = () => import(/* webpackChunkName: "login" */ '@/views/login/ForgotPassword');

const AdmList = () => import(/* webpackChunkName: "adm" */ '@/views/adm/AdmList.vue');
const AdmNew = () => import(/* webpackChunkName: "adm" */ '@/views/adm/AdmNew.vue');
const AdmEdit = () => import(/* webpackChunkName: "adm" */ '@/views/adm/AdmEdit.vue');

const CandidatesList = () => import(/* webpackChunkName: "candidates" */ '@/views/candidates/CandidatesList.vue');
const CandidatesNew = () => import(/* webpackChunkName: "candidates" */ '@/views/candidates/CandidatesNew.vue');
const CandidatesView = () => import(/* webpackChunkName: "candidates" */ '@/views/candidates/CandidatesView.vue');

const Metrics = () => import(/* webpackChunkName: "Metrics" */ '@/views/metrics/Metrics.vue');

const Research = () => import(/* webpackChunkName: "Research" */ '@/views/candidates/Research.vue');

const NotFound = () => import(/* webpackChunkName: "notFound" */ '@/views/404.vue');

const routes = [
    {
        path: '/',
        redirect: '/adm',
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
        meta: {
            module: '',
            mode: '',
            showNav: false,
        },
    },
    {
        path: '/app',
        name: 'aplication',
        component: aplication,
        meta: {
            login: true,
        },
        children: [
            {
                path: '/adm',
                name: 'AdmList',
                component: AdmList,
                meta: {
                    module: 'Administradores',
                    mode: 'all',
                    showNav: true,
                },
            },
            {
                path: '/adm/new',
                name: 'AdmNew',
                component: AdmNew,
                meta: {
                    module: 'Administradores',
                    mode: 'all',
                    showNav: false,
                },
            },
            {
                path: '/adm/:admID',
                name: 'AdmEdit',
                component: AdmEdit,
                meta: {
                    module: 'Administradores',
                    mode: 'all',
                    showNav: false,
                },
            },
            {
                path: '/candidates',
                name: 'CandidatesList',
                component: CandidatesList,
                meta: {
                    module: 'Candidatos',
                    mode: 'all',
                    showNav: true,
                },
            },
            {
                path: '/candidates/new',
                name: 'CandidatesNew',
                component: CandidatesNew,
                meta: {
                    module: 'Candidatos',
                    mode: 'all',
                    showNav: false,
                },
            },
            {
                path: '/candidates/:candidateID',
                name: 'CandidatesView',
                component: CandidatesView,
                meta: {
                    module: 'Candidatos',
                    mode: 'all',
                    showNav: false,
                },
            },
            {
                path: '/research/:candidateID',
                name: 'Research',
                component: Research,
                meta: {
                    module: 'research',
                    mode: 'all',
                    showNav: false,
                },
            },
            {
                path: '/metrics',
                name: 'Metrics',
                component: Metrics,
                meta: {
                    module: 'Métricas',
                    mode: 'all',
                    showNav: true,
                },
            },
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: login,
        beforeEnter: clientIsLoggedIn,
    },
    {
        path: '/forgotPassword/:token',
        name: 'ForgotPassword',
        component: ForgotPassword,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return { top: 0 };
    },
});

function clientIsLoggedIn() {
    console.log('auth', auth.logged);
    // if (auth.isAuthenticated()) {
    //     router.push({ name: 'AdmList' });
    // }
}

router.beforeEach((to) => {
    if (to.meta.login) {
        // verifica se a rota precisa ser autenticada
        console.log(auth.isAuthenticated());
        if (auth.isAuthenticated()) {
            //verifica se o usuario tem acesso
            if (to.meta.mode === 'all' || auth.accessLevel == to.meta.mode) {
                return true;
            } else {
                //verifica se o caminho e o 404 e se for deixa passar
                if (to.path == '/not/found') {
                    return true;
                } else {
                    return { path: '/not/found' };
                }
            }
        } else {
            console.log('usuário não logado, rota protegida');
            auth.logout();
            return { path: '/login', query: { to: to.path } };
        }
    } else {
        return true;
    }
});

export default router;
